import React from 'react';
import {Heading, Link, Text} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

const BannerPhone = (props) => {
    return (
        <div className="">
            <Link href={SiteData.telLink} _hover={{textDecoration: 'none', color: 'white'}}>
                <Heading as={'h4'} color={'#fff'} fontSize={'3xl'}>{props.heading}</Heading>
                <Text fontSize={'3xl'} fontWeight={'bold'}>{props.phone}</Text>
            </Link>
        </div>
    );
};

export default BannerPhone;